function getLang(lang,data){

    let tempLang = ""
    if (!lang || lang === undefined) {
      tempLang = "sv"
    } else {
      tempLang = "en"
    }
    if(data){
        return data.edges.filter(edges => {
          return edges.node.node_locale === tempLang
        })
    }else{
        return tempLang
    }
    
  }

export default getLang;